import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// User defined components
import { withAnalytics } from "../App";
import BlogFeed from "../modules/blog/BlogFeed";
import BlogDetail from "../modules/blog/BlogDetail";
import AuthPage from "../modules/auth/AuthPage";
import JobFeed from "../modules/job/JobFeed";
// import JobCreate from "../modules/job/JobCreate";
import JobPage from "../modules/job/JobPage";
import InfluencerJobPage from "../modules/job/InfluencerDetails";

import InfluencerJobCreate from "../modules/job/InfluencerJobCreate";
import Profile from "../modules/job/Profile";
import InfluencerJobUpdate from "../modules/job/InfluencerJobUpdate";

export function PrivateRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/feed" />} />
      <Route
        exact
        path="/blog"
        element={React.createElement(withAnalytics(BlogFeed))}
      />
      <Route
        exact
        path="/blog/:slug"
        element={React.createElement(withAnalytics(BlogDetail))}
      />
      <Route
        exact
        path="/auth/*"
        element={React.createElement(withAnalytics(AuthPage))}
      />
      <Route
        exact
        path="/feed"
        element={React.createElement(withAnalytics(JobFeed))}
      />
      <Route
        exact
        path="/create"
        element={React.createElement(withAnalytics(InfluencerJobCreate))}
      />
      {/* <Route path="/createjob" element={} /> */}
      <Route
        exact
        path="/profile"
        element={React.createElement(withAnalytics(Profile))}
      />
      <Route exact path="/job/:id" element={React.createElement(withAnalytics(InfluencerJobPage))} />
      <Route exact path="/updatejob/:id" element={React.createElement(withAnalytics(InfluencerJobUpdate))} />
    </Routes>
  );
}
