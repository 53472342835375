import React, { useEffect, useState, useCallback } from "react";
import ChatComponent from "./ChatComponent";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
// Fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faCircleChevronRight,
  faCircleChevronDown,
  faCircleChevronUp,
  faFilter,
  faCalendar,
  faLocationDot,
  faSackDollar,
  faHandshakeAngle,
  faMagnifyingGlass,
  faMessage,
  faPlus,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart, faPenToSquare } from "@fortawesome/free-regular-svg-icons";

// Bootstrap components
import {
  Card,
  Col,
  Row,
  Form,
  Button,
  ProgressBar,
  Container,
  Image,
  Badge,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

// User defined components
import { jobActionCreators } from "./JobReducer";
import axiosInstance from "../../config/Axios";
import CategoryModel from "../../models/CategoryModel";
import LocationModel from "./../../models/LocationModel";
import BudgetModel from "../../models/BudgetModel";
import UserProfile from "./UserProfile";
import { useIsMobile, useIsTablet } from "../../config/Screen";

// JobList component
const JobList = () => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [isJobLoaded, setisJobLoaded] = useState(false);
  const [isCategoryLoaded, setIsCategoryLoaded] = useState(false);
  const [isBudgetLoaded, setIsBudgetLoaded] = useState(false);
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);
  const [clickedHearts, setClickedHearts] = useState([]);
  const [showLocationsState, setShowLocationsState] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [appliedBudgetfilters, setAppliedBudgetfilters] = useState([]);
  const [appliedCategoryfilters, setAppliedCategoryfilters] = useState([]);
  const [appliedLocationfilters, setAppliedLocationfilters] = useState([]);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [searchQuery, setSearchQuery] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [searchQueryDesktop, setSearchQueryDesktop] = useState("");
  const [, updateState] = useState();
  const [locationFiltersUpdated, setLocationFiltersUpdated] = useState(false);
  const [categoryFiltersUpdated, setCategoryFiltersUpdated] = useState(false);
  const [messages, setMessages] = useState([]);
  const [budgetFiltersUpdated, setBudgetFiltersUpdated] = useState(false);
  const [showUsername, setShowUserName] = useState(false);
  const forceUpdate = useCallback(() => updateState({}), []);
  const userId = useSelector(({ auth }) => auth.userId);
  const navigate = useNavigate();
  const [showUsers, setShowUsers] = useState(false); // To toggle the user list
  const [error, setError] = useState(null);
  const [isUsersLoaded, setIsUsersLoaded] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null); // State for selected applicant to chat
  const [showChats, setShowChats] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const response = await axiosInstance.get(`/users/${userId}`);
      console.log(response.data);
      setUserName(response.data.name);
    };

    fetchUser();
  }, [userId]);
  var totalApplicants;
  const parseBudget = (budget) => {
    if (budget.includes("K")) {
      return parseFloat(budget.replace("K", "")) * 1000;
    } else if (budget.includes("L")) {
      return parseFloat(budget.replace("L", "")) * 100000;
    }
    return parseFloat(budget);
  };
  useEffect(() => {
    // Fetch jobs from the server
    const fetchJobs = async () => {
      try {
        const response = await axiosInstance.get("/posts");
        const data = response.data;
        setJobs(data);
        setisJobLoaded(true);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    // Fetch categories from the server
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/categories");
        const data = response.data;
        const categories = data.map((category) => new CategoryModel(category));
        const serializableCategories = categories.map((category) => ({
          ...category,
        }));
        dispatch(jobActionCreators.addCategory(serializableCategories));
        // Save the categories into CategoryModel
        CategoryModel.save(categories);
        setIsCategoryLoaded(true);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleApplicantClicks = (applicantId) => {
    setSelectedApplicantId(applicantId); // Set the clicked applicant's ID
  };
  const handleCloseChatting = () => {
    setSelectedApplicantId(null); // Close the chat window
  };
  const [isClosing, setIsClosing] = useState(false);

  const closeChatWithAnimation = () => {
    setIsClosing(true); // Trigger the closing animation
    setTimeout(() => {
      handleCloseChatting(); // Call the actual close handler after the animation
      setIsClosing(false); // Reset the state
    }, 500); // Match the duration of the CSS transition
  };
  useEffect(() => {
    // Fetch locations from the server
    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get("/locations"); // Assuming your endpoint is /locations
        const data = response.data;
        dispatch(jobActionCreators.addLocation(data));
        const locations = data.map((location) => new LocationModel(location));
        // Save the locations into LocationModel
        LocationModel.save(locations);
        setIsLocationLoaded(true);
        forceUpdate(); // Force re-render
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    // Fetch budgets from the server
    const fetchBudgets = async () => {
      try {
        const response = await axiosInstance.get("/budgets"); // Assuming your endpoint is /budgets
        const data = response.data;
        const budgets = data.map((budget) => new BudgetModel(budget));
        // Save the budgets into BudgetModel
        BudgetModel.save(budgets);
        setIsBudgetLoaded(true);
        forceUpdate(); // Force re-render
      } catch (error) {
        console.error("Error fetching budgets:", error);
      }
    };

    fetchBudgets();
  }, []);

  const handlePlusclick = () => {
    navigate("/create");
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get("/messages/senders");
        const data = response.data;
        const senderIds = response.data.users;

        const userDetails = await Promise.all(
          senderIds.map(async (id) => {
            const userResponse = await axiosInstance.get(`/users/${id}`);
            return { id: userResponse.data._id, name: userResponse.data.name };
          })
        );

        if (data && data.users) {
          setShowUsers(userDetails);
          setIsUsersLoaded(true);
        } else {
          throw new Error("No users found in the response");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setError(error.message);
      }
    };

    fetchUsers();
  }, []);

  const handleIconClick = () => {
    setShowUserName(!showUsername);
    setSelectedApplicantId(null);
    setShowChats((prevState) => !prevState);
  };

  const CombinedFilters = ({
    appliedLocationfilters,
    appliedCategoryfilters,
    appliedBudgetfilters,
    removeLocationFilter,
    removeCategoryFilter,
    removeMobileFilter,
  }) => {
    const handleRemoveFilter = (filter, type) => {
      switch (type) {
        case "location":
          removeLocationFilter(filter);
          break;
        case "category":
          removeCategoryFilter(filter);
          break;
        case "budget":
          removeMobileFilter(filter);
          break;
        default:
          break;
      }
    };

    const combinedFilters = [
      ...appliedLocationfilters.map((filter) => ({ filter, type: "location" })),
      ...appliedCategoryfilters.map((filter) => ({ filter, type: "category" })),
      ...appliedBudgetfilters.map((filter) => ({ filter, type: "budget" })),
    ];

    return (
      <>
        {combinedFilters.map((item, index) => (
          <Button
            key={index}
            className="app-jobList-FilterCards-overflow app-jobList-MobileFilter-badge me-2 mt-1"
            style={{ display: "inline-block" }}
            onClick={() => handleRemoveFilter(item.filter, item.type)}
          >
            {item.filter} <FontAwesomeIcon icon={faXmark} />
          </Button>
        ))}
      </>
    );
  };

  const filterJobsBySearchQuery = (job) => {
    if (!searchQuery) return true;
    const keywords = searchQuery.toLowerCase().split(" ");
    return (
      keywords.every(
        (keyword) =>
          job.title.toLowerCase().includes(keyword) ||
          job.content.toLowerCase().includes(keyword) ||
          job.location.toLowerCase().includes(keyword) ||
          job.authorName.toLowerCase().includes(keyword)
      ) ||
      job.categoryBudget.some((item) => {
        const category = CategoryModel.getNameById(item.category);
        return (
          category && category.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    );
  };
  const totalFilteredJobs = jobs
    .filter((job) => new Date(job.jobEndDate) > new Date()) // Filter out expired jobs
    .filter(filterJobsBySearchQuery) // Filter by search query
    .filter((job) => {
      if (appliedLocationfilters.length === 0) return true;
      return appliedLocationfilters.includes(job.location);
    }) // Filter by location
    .filter((job) => {
      if (appliedCategoryfilters.length === 0) return true;
      return appliedCategoryfilters.some((category) =>
        job.categoryBudget
          .map((item) => CategoryModel.getNameById(item.category))
          .includes(category)
      );
    }) // Filter by category
    .filter((job) => {
      if (appliedBudgetfilters.length === 0) return true;
      return appliedBudgetfilters.some((budgetRange) => {
        const [minBudgetStr, maxBudgetStr] = budgetRange.split("-");
        var lastChar = maxBudgetStr.slice(-1);
        if (minBudgetStr === "75" && lastChar === "L") {
          lastChar = "K";
        }
        const minBudget = parseBudget(minBudgetStr + lastChar);
        const maxBudget = parseBudget(maxBudgetStr);
        return job.categoryBudget.some((item) => {
          const jobBudget = parseInt(item.budget);
          return jobBudget >= minBudget && jobBudget <= maxBudget;
        });
      });
    })
    // Filter by budget
    .filter((job) => job.isActive === true && job.isCompleted === false).length; // Filter by job status // Count the total number of filtered jobs

  function showLocations() {
    return (
      <Card className="app-jobList-FiltersCards mt-2 mx-auto position-relative">
        <Row
          className="app-jobList-FiltersCross mt-1 me-2 position-absolute end-0"
          onClick={toggleLocations}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Row>
        <Row className="app-jobList-FilterHeading mx-auto">
          Choose your location
        </Row>
        <Row className="mt-3 mx-auto  ">
          <Col className="app-jobList-FilterCards-overflow">
            {LocationModel.locations.map((location) => (
              <Button
                key={location.id}
                className="app-jobList-FilterCard-badge me-2 mb-2"
                value={location.name}
                id={location.id}
                onClick={() => updateLocationFilters(location.name, true)}
              >
                {location.name}
              </Button>
            ))}
          </Col>
        </Row>
      </Card>
    );
  }

  const AppliedLocationfilters = ({
    appliedLocationfilters,
    removeLocationFilter,
  }) => {
    const handleRemoveFilter = (filter) => {
      removeLocationFilter(filter);
    };

    return (
      <Col className="d-flex">
        {appliedLocationfilters.map((filter, index) => (
          <Button
            key={index}
            className="app-jobList-FilterCards-overflow app-jobList-MobileFilter-badge me-2 mt-1"
            onClick={() => handleRemoveFilter(filter)}
          >
            {filter} <FontAwesomeIcon icon={faXmark} />
          </Button>
        ))}
      </Col>
    );
  };

  const removeLocationFilter = (filterToRemove) => {
    setAppliedLocationfilters(
      appliedLocationfilters.filter((filter) => filter !== filterToRemove)
    );
  };

  //------------------------------------------------------------------------------------------------

  const updateCategoryFilters = (filter, checked) => {
    if (checked) {
      // Check if the filter is already applied
      if (!appliedCategoryfilters.includes(filter)) {
        // If not applied, add the filter
        setAppliedCategoryfilters([...appliedCategoryfilters, filter]);
      }
    } else {
      // If unchecked, remove the filter
      setAppliedCategoryfilters(
        appliedCategoryfilters.filter((f) => f !== filter)
      );
    }
    setCategoryFiltersUpdated(true);
  };
  const updateLocationFilters = (filter, checked) => {
    if (checked) {
      if (!appliedLocationfilters.includes(filter)) {
        setAppliedLocationfilters([...appliedLocationfilters, filter]);
      }
    } else {
      setAppliedLocationfilters(
        appliedLocationfilters.filter((f) => f !== filter)
      );
    }
    setLocationFiltersUpdated(true);
  };
  function showCategoriesCard() {
    return (
      <Card className="app-jobList-FiltersCards mt-2 mx-auto position-relative">
        <Row
          className="app-jobList-FiltersCross mt-1 me-2 position-absolute end-0"
          onClick={toggleCategories}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Row>
        <Row className="app-jobList-FilterHeading mx-auto">
          Choose your Categories
        </Row>
        <Row className="mt-3 mx-auto ">
          <Col className="app-jobList-FilterCards-overflow">
            {CategoryModel.categories.map((category) => (
              <Button
                className="app-jobList-FilterCard-badge me-2 mb-2"
                key={category.id}
                value={category.name}
                label={category.name}
                id={category.id}
                onClick={() => updateCategoryFilters(category.name, true)}
              >
                {category.name}
              </Button>
            ))}
          </Col>
          {/* <Col lg={1}>--></Col> */}
        </Row>
      </Card>
    );
  }

  const AppliedCategoryfilters = ({
    appliedCategoryfilters,
    removeCategoryFilter,
  }) => {
    const handleRemoveFilter = (filter) => {
      removeCategoryFilter(filter);
    };

    return (
      <Col className="d-flex">
        {appliedCategoryfilters.map((filter, index) => (
          <Button
            key={index}
            className="app-jobList-FilterCards-overflow app-jobList-MobileFilter-badge me-2 mt-1"
            onClick={() => handleRemoveFilter(filter)}
          >
            {filter} <FontAwesomeIcon icon={faXmark} />
          </Button>
        ))}
      </Col>
    );
  };

  //updated

  const removeCategoryFilter = (filterToRemove) => {
    setAppliedCategoryfilters(
      appliedCategoryfilters.filter((filter) => filter !== filterToRemove)
    );
  };

  //-------------------------------------------------------------------------------------------

  const updateBudgetFilters = (filter, checked) => {
    const formattedFilter = filter;

    if (checked) {
      if (!appliedBudgetfilters.includes(formattedFilter)) {
        setAppliedBudgetfilters([...appliedBudgetfilters, formattedFilter]);
      }
    } else {
      setAppliedBudgetfilters(
        appliedBudgetfilters.filter((f) => f !== formattedFilter)
      );
    }
    setBudgetFiltersUpdated(true);
  };

  function showBudgetCard() {
    return (
      <Card className="app-jobList-FiltersCards mt-2 mx-auto position-relative">
        <Row
          className="app-jobList-FiltersCross mt-1 me-2 position-absolute end-0"
          onClick={toggleBudget}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Row>
        <Row className="app-jobList-FilterHeading mx-auto">
          Choose your budget
        </Row>
        <Row className="mt-3 mx-auto">
          <Col className="app-jobList-FilterCards-overflow">
            {BudgetModel.budgets.map((budget) => (
              <Button
                key={budget.id}
                className="app-jobList-FilterCard-badge me-2 mb-2"
                onClick={() => updateBudgetFilters(budget.amount, true)} // Assuming updateBudgetFilters handles the filter change
              >
                {budget.amount}
              </Button>
            ))}
          </Col>
        </Row>
      </Card>
    );
  }

  const AppliedBudgetfilters = ({
    appliedBudgetfilters,
    removeMobileFilter,
  }) => {
    const handleRemoveFilter = (filter) => {
      removeMobileFilter(filter);
    };

    return (
      <Col className="d-flex">
        {appliedBudgetfilters.map((filter, index) => (
          <Button
            key={index}
            className="app-jobList-FilterCards-overflow app-jobList-MobileFilter-badge me-2 mt-1"
            onClick={() => handleRemoveFilter(filter)}
          >
            {filter} <FontAwesomeIcon icon={faXmark} />
          </Button>
        ))}
      </Col>
    );
  };

  //updated

  const removeMobileFilter = (filterToRemove) => {
    setAppliedBudgetfilters(
      appliedBudgetfilters.filter((filter) => filter !== filterToRemove)
    );
  };

  //-----------------------------------------------------------------------------------------------------------------

  const toggleLocations = () => {
    setShowCategories(false);
    setShowBudget(false);
    setShowLocationsState(!showLocationsState);
  };

  const toggleCategories = () => {
    setShowLocationsState(false);
    setShowBudget(false);
    setShowCategories(!showCategories);
  };
  const toggleBudget = () => {
    setShowLocationsState(false);
    setShowCategories(false);
    setShowBudget(!showBudget);
  };
  const handleHeartClick = (jobId) => {
    // Toggle the clickedHearts state for the clicked job ID
    setClickedHearts((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId], // Toggle the state
    }));
  };

  const handleEditJob = (jobId, jobAuthorId) => {
    navigate(`/updatejob/${jobId}`, { state: { jobId, jobAuthorId } });
  };

  // const updateFilters = (filter, checked) => {
  //   if (checked) {
  //     setAppliedfilters([...appliedfilters, filter]);
  //   } else {
  //     setAppliedfilters(appliedfilters.filter((f) => f !== filter));
  //   }
  // };
  const handleSearchInputChangeDesktop = (event) => {
    setSearchQueryDesktop(event.target.value);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCategories = CategoryModel.categories
    ? CategoryModel.categories.filter((category) =>
        category.name.toLowerCase().includes(searchQueryDesktop.toLowerCase())
      )
    : [];

  function desktopFilters() {
    return (
      <>
        <Row className="app-filter-container">
          <Row className="app-filter-box poppins-regular">All Filters</Row>
          <Row
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            xm={12}
            className="app-filter-box mt-3"
          >
            <Row className="poppins-semibold mt-2">Locations</Row>
            {LocationModel.locations &&
              LocationModel.locations.map((location) => (
                <Form.Check
                  key={location.id}
                  className="app-filter-checkbox mt-1"
                  type="checkbox"
                  value={location.name}
                  label={location.name}
                  id={location.id}
                  checked={appliedLocationfilters.includes(location.name)}
                  onChange={(e) =>
                    updateLocationFilters(location.name, e.target.checked)
                  }
                />
              ))}
          </Row>
          <Row className="app-filter-box mt-3">
            <Row className="poppins-semibold mt-2">Category</Row>
            <Form className="m-0 p-0 mt-3">
              <Form.Control
                type="search"
                placeholder="Search category"
                className="app-filter-search mb-3"
                aria-label="Search"
                value={searchQueryDesktop}
                onChange={handleSearchInputChangeDesktop}
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="ms-1 app-joblist-category-search-icon position-absolute"
              />
            </Form>
            <Row className="app-filter-h4 poppins-regular">Popular</Row>
            {filteredCategories.map((category) => (
              <Form.Check
                key={category.id}
                className="app-filter-checkbox mt-1"
                type="checkbox"
                value={category.name}
                label={category.name}
                id={category.id}
                checked={appliedCategoryfilters.includes(category.name)}
                onChange={(e) => {
                  updateCategoryFilters(category.name, e.target.checked);
                }}
              />
            ))}
          </Row>
          <Row className="app-filter-box mt-3">
            <Row className="poppins-semibold mt-2">Budget</Row>
            <Row className="app-filter-checkbox mt-3">
              {BudgetModel.budgets &&
                BudgetModel.budgets.map((budget) => (
                  <Form.Check
                    key={budget.id}
                    className="app-filter-checkbox ms-2 mt-1"
                    type="checkbox"
                    value={budget.amount}
                    label={budget.amount}
                    id={budget.id}
                    checked={appliedBudgetfilters.includes(budget.amount)} // Assuming appliedBudgetFilters contains selected budget amounts
                    onChange={(e) =>
                      updateBudgetFilters(budget.amount, e.target.checked)
                    } // Assuming updateBudgetFilters handles checkbox change
                  />
                ))}
            </Row>
          </Row>
        </Row>
      </>
    );
  }

  function jobCard() {
    // Function to handle search input change
    return (
      <Row className="mt-1">
        {jobs
          .filter((job) => new Date(job.jobEndDate) > new Date()) // Filter out expired jobs
          .filter(filterJobsBySearchQuery)
          .filter((job) => {
            if (appliedLocationfilters.length === 0) return true;
            return appliedLocationfilters.includes(job.location);
          })
          .filter((job) => {
            // Filter by category
            if (appliedCategoryfilters.length === 0) return true;
            return appliedCategoryfilters.some((category) =>
              job.categoryBudget
                .map((item) => CategoryModel.getNameById(item.category))
                .includes(category)
            );
          })
          .filter((job) => {
            // Filter by budget
            if (appliedBudgetfilters.length === 0) return true;
            return appliedBudgetfilters.some((budgetRange) => {
              const [minBudgetStr, maxBudgetStr] = budgetRange.split("-");
              var lastChar = maxBudgetStr.slice(-1);
              if (minBudgetStr === "75" && lastChar === "L") {
                lastChar = "K";
              }
              const minBudget = parseBudget(minBudgetStr + lastChar);
              const maxBudget = parseBudget(maxBudgetStr);
              return job.categoryBudget.some((item) => {
                const jobBudget = parseInt(item.budget);
                return jobBudget >= minBudget && jobBudget <= maxBudget;
              });
            });
          })
          .filter((job) => job.isActive === true && job.isCompleted === false)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort jobs by latest
          .map((job) => {
            // Calculate total applicants for the job
            totalApplicants = job.categoryBudget.reduce(
              (acc, item) => acc + item.applicants.length,
              0
            );
            return (
              <Card key={job._id} className="mt-1 app-job-list-card">
                <Card.Body>
                  {!isMobile && (
                    <Row>
                      <Col
                        xxl={11}
                        xl={11}
                        lg={11}
                        md={11}
                        sm={11}
                        xs={11}
                        className="d-flex align-items-center"
                      >
                        <Image
                          className="me-2 img-fluid"
                          src="https://via.placeholder.com/32"
                          roundedCircle
                        />
                        <Card.Text className="app-job-list-author poppins-medium">
                          {job.authorName}{" "}
                        </Card.Text>
                      </Col>
                      {job.author !== userId ? (
                        <Col
                          xxl={1}
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          className="text-end justify-content-center align-items-center p-1"
                        >
                          <OverlayTrigger
                            placement="bottom" // Position of the tooltip (can be "top", "right", "bottom", "left")
                            overlay={
                              <Tooltip id={`tooltip-top`}>Favorite</Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              className="app-joblist-heartclick"
                              icon={faHeart}
                              onClick={() => handleHeartClick(job._id)}
                              color={clickedHearts[job._id] ? "red" : "grey"} // Change color based on state
                            />
                          </OverlayTrigger>
                        </Col>
                      ) : (
                        <Col
                          xxl={1}
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          className="text-end justify-content-center align-items-center p-1"
                        >
                          <OverlayTrigger
                            placement="bottom" // Position of the tooltip (can be "top", "right", "bottom", "left")
                            overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}
                          >
                            <FontAwesomeIcon
                              className="app-joblist-heartclick"
                              icon={faPenToSquare}
                              style={{ color: "grey" }}
                              onClick={() => handleEditJob(job._id, job.author)}
                            />
                          </OverlayTrigger>
                        </Col>
                      )}
                    </Row>
                  )}
                  {isMobile && (
                    <Row>
                      <Col className="d-flex align-items-center">
                        <Image
                          className="me-2 img-fluid"
                          src="https://via.placeholder.com/32"
                          roundedCircle
                        />
                        <Card.Text className="app-job-list-author poppins-medium">
                          {job.authorName}
                        </Card.Text>
                      </Col>
                      <Col className="text-end justify-content-center align-items-center mt-1">
                        {job.author !== userId ? (
                          <Col
                            xxl={1}
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            className="text-end justify-content-center align-items-center p-1"
                          >
                            <OverlayTrigger
                              placement="bottom" // Position of the tooltip (can be "top", "right", "bottom", "left")
                              overlay={
                                <Tooltip id={`tooltip-top`}>Favorite</Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                className="app-joblist-heartclick"
                                icon={faHeart}
                                onClick={() => handleHeartClick(job._id)}
                                color={clickedHearts[job._id] ? "red" : "grey"} // Change color based on state
                              />
                            </OverlayTrigger>
                          </Col>
                        ) : (
                          <Col
                            xxl={1}
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            className="text-end justify-content-center align-items-center p-1"
                          >
                            <OverlayTrigger
                              placement="bottom" // Position of the tooltip (can be "top", "right", "bottom", "left")
                              overlay={
                                <Tooltip id={`tooltip-top`}>Edit</Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                className="app-joblist-heartclick"
                                icon={faPenToSquare}
                                style={{ color: "grey" }}
                                onClick={() =>
                                  handleEditJob(job._id, job.author)
                                }
                              />
                            </OverlayTrigger>
                          </Col>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Link
                    to={`/job/${job._id}`}
                    className="app-joblist-card-link p-0"
                  >
                    <Row className="mt-3">
                      <Col>
                        <Card.Title className="app-job-list-title poppins-semibold">
                          {job.title}
                        </Card.Title>
                      </Col>
                    </Row>
                    <Row xxl={8} xl={8} lg={8} md={8}>
                      <Col>
                        <Card.Text className="app-job-list-content poppins-regular">
                          {job.content}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Link>
                  <Row>
                    <Col lg={9}>
                      <Link
                        to={`/job/${job._id}`}
                        className="app-joblist-card-link p-0"
                      >
                        <Row>
                          <Col>
                            {job.jobStartDate.split("T")[0] ===
                            job.jobEndDate.split("T")[0] ? (
                              <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                <FontAwesomeIcon icon={faCalendar} />{" "}
                                {new Date(job.jobStartDate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </Badge>
                            ) : (
                              <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                <FontAwesomeIcon icon={faCalendar} />{" "}
                                {new Date(job.jobStartDate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}{" "}
                                to{" "}
                                {new Date(job.jobEndDate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </Badge>
                            )}
                            <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                              <FontAwesomeIcon icon={faLocationDot} />{" "}
                              {job.location}
                            </Badge>
                            {job.categoryBudget.map((item, index) => (
                              <React.Fragment key={index}>
                                <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                  {job.isPaid ? (
                                    <FontAwesomeIcon icon={faSackDollar} />
                                  ) : (
                                    <FontAwesomeIcon icon={faHandshakeAngle} />
                                  )}{" "}
                                  {CategoryModel.getNameById(item.category)}
                                  {job.isPaid ? " | Budget is ₹" : null}
                                  {job.isPaid ? item.budget : null}
                                </Badge>
                              </React.Fragment>
                            ))}
                          </Col>
                        </Row>
                      </Link>
                    </Col>
                    {!isMobile && !isTablet && (
                      <Col className="text-end align-self-end">
                        <Col className="app-joblist-applied text-muted">
                          Applied {totalApplicants}
                        </Col>
                        <Link
                          to={`/job/${job._id}`}
                          className="app-job-list-details-link poppins-semibold"
                        >
                          View details{" "}
                          <FontAwesomeIcon icon={faCircleChevronRight} />
                        </Link>
                      </Col>
                    )}
                    {(isMobile || isTablet) && (
                      <Row className="align-items-center mt-1">
                        <Col className="ms-2 justify-content-center app-joblist-applied text-muted">
                          Applied {totalApplicants}
                        </Col>
                        <Col className="text-end align-self-end justify-content-center">
                          <Link
                            to={`/job/${job._id}`}
                            className="app-job-list-details-link poppins-semibold"
                          >
                            View details{" "}
                            <FontAwesomeIcon icon={faCircleChevronRight} />
                          </Link>
                        </Col>
                      </Row>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
      </Row>
    );
  }

  if (
    !isCategoryLoaded ||
    !isBudgetLoaded ||
    !isLocationLoaded ||
    !isJobLoaded
  ) {
    return (
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProgressBar
            animated
            now={100}
            variant="dark"
            style={{ height: "3px" }}
          />
        </Col>
      </Row>
    );
  }

  function plusIcon() {
    return (
      <Container className="position-relative">
        <Row
          className="app-jobList-plus-icon fixed-bottom ms-4 mb-5"
          onClick={handlePlusclick}
        >
          <FontAwesomeIcon className="" icon={faPlus} />
        </Row>
      </Container>
    );
  }
  if (isMobile || isTablet) {
    return (
      <>
        <Container className="app-job-list-sm-container">
          <Row className="poppins-semibold app-select-category-menu-heading mt-3 mb-2 ms-2">
            {totalFilteredJobs} jobs
            <Col>
              <FontAwesomeIcon icon={faCircleChevronDown} />
            </Col>{" "}
          </Row>
          <Row className="app-job-list-bar mx-auto">
            <Col className="d-flex p-0">
              <div className="app-job-list-search-bar">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="app-joblist-search-icon"
                />
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="app-job-list-search"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
            <Col xs="auto"></Col>
          </Row>
          <Row className="mx-auto app-job-list-sm-filter-container">
            <div className="app-job-list-mobile-filter mt-4 d-flex">
              <Button className="app-job-list-mobile-filter-card me-2 d-flex">
                Filters
                <span className="ms-1">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </Button>
              <AppliedBudgetfilters
                appliedBudgetfilters={appliedBudgetfilters}
                removeMobileFilter={removeMobileFilter}
              />
              <AppliedCategoryfilters
                appliedCategoryfilters={appliedCategoryfilters}
                removeCategoryFilter={removeCategoryFilter}
              />
              <AppliedLocationfilters
                appliedLocationfilters={appliedLocationfilters}
                removeLocationFilter={removeLocationFilter}
              />
              <Button
                className="app-job-list-mobile-filter-card ms-2 d-flex"
                onClick={toggleLocations}
              >
                Locations
                <span className="ms-1">
                  {showLocationsState ? (
                    <FontAwesomeIcon icon={faCircleChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleChevronDown} />
                  )}
                </span>
              </Button>
              <Button
                className="app-job-list-mobile-filter-card ms-2 d-flex"
                onClick={toggleCategories}
              >
                Category
                <span className="ms-1">
                  {showCategories ? (
                    <FontAwesomeIcon icon={faCircleChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleChevronDown} />
                  )}
                </span>
              </Button>
              <Button
                className="app-job-list-mobile-filter-card ms-2 d-flex"
                onClick={toggleBudget}
              >
                Budget
                <span className="ms-1">
                  {showBudget ? (
                    <FontAwesomeIcon icon={faCircleChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleChevronDown} />
                  )}
                </span>
              </Button>
            </div>
          </Row>
          {showLocationsState && showLocations()}
          {showCategories && showCategoriesCard()}
          {showBudget && showBudgetCard()}
          {jobCard()}
          <div
            className={`position-fixed bottom-0 end-0 ${
              selectedApplicantId === null ? "w-50 me-2" : "me-2"
            }`}
          >
            <Row className="d-flex">
              {/* Chat Component Column */}
              {selectedApplicantId && (
                <Col className="" xl={7}>
                  <ChatComponent
                    receiverId={selectedApplicantId}
                    handleCloseChatting={handleCloseChatting}
                  />
                </Col>
              )}

              {/* User List Column */}
              <Col
                className="app-joblist-sender-list-section border bg-white"
                xl={selectedApplicantId ? 6 : 10}
              >
                <Row className="border-bottom position-relative">
                  <Col
                    className="text-success align-items-center cursorpointer ms-0 d-flex justify-content-center"
                    onClick={handleIconClick}
                  >
                    Messaging
                    <FontAwesomeIcon
                      className="position-absolute end-0 me-2"
                      icon={showUsername ? faChevronDown : faChevronUp}
                    />
                  </Col>
                </Row>

                {error ? (
                  <p className="text-danger">Error: {error}</p>
                ) : !isUsersLoaded ? (
                  <p>Loading users...</p>
                ) : (
                  showUsername && (
                    <Col className="" style={{ maxHeight: "" }}>
                      {showUsers.map((user, index) => {
                        return (
                          <Row
                            key={index}
                            onClick={() => handleApplicantClicks(user.id)}
                            className="cursorpointer border-bottom d-flex align-items-center ms-2"
                          >
                            <div
                              className="rounded-circle bg-secondary ms-2 my-1 me-2 d-flex justify-content-center align-items-center"
                              style={{
                                width: "30px",
                                height: "30px",
                                color: "white",
                                fontWeight: "",
                              }}
                            >
                              {user.name.charAt(0).toUpperCase()}{" "}
                              {/* Display the first letter of the user's name */}
                            </div>
                            <Col className="p-0">
                              <Col className="app-joblist-username-color">
                                {user.name}
                              </Col>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  )
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container className="mt-4 mb-5 position-relative">
          <Row>
            <Col xxl={2} xl={2} lg={2}>
              {desktopFilters()}
            </Col>
            <Col xxl={7} xl={7} lg={7}>
              <Row className="poppins-semibold app-select-category-menu-heading mb-2">
                We found {totalFilteredJobs} jobs for you
              </Row>
              <Row className="app-job-list-bar mb-1">
                <Col className="d-flex p-0">
                  <div className="app-job-list-search-bar">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="app-joblist-search-icon"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      className="app-job-list-search"
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </Col>
                <Col xs="auto"></Col>
              </Row>
              {/* <div className="mx-auto app-job-list-sm-filter-container2 app-job-list-mobile-filter mt-4 mb-3 d-flex"></div> */}

              <Col className="d-flex flex-wrap">
                <CombinedFilters
                  appliedLocationfilters={appliedLocationfilters}
                  appliedCategoryfilters={appliedCategoryfilters}
                  appliedBudgetfilters={appliedBudgetfilters}
                  removeLocationFilter={removeLocationFilter}
                  removeCategoryFilter={removeCategoryFilter}
                  removeMobileFilter={removeMobileFilter}
                />
              </Col>
              {jobCard()}
            </Col>
            <Col xxl={3} xl={3} lg={3}>
              <UserProfile />
            </Col>

            <div
              className={`position-fixed bottom-0 end-0 ${
                selectedApplicantId === null
                  ? "app-joblist-uselist-width"
                  : "w-50"
              }`}
            >
              <Row className="d-flex">
                {/* Chat Component Column */}
                {selectedApplicantId && (
                  <Col
                    className={` ${
                      isClosing
                        ? "app-chatanimation-slide-down"
                        : "app-chatanimation-slide-up"
                    }`}
                    xxl={7}
                    xl={7}
                    lg={7}
                    md={7}
                    sm={7}
                    xs={7}
                  >
                    <ChatComponent
                      receiverId={selectedApplicantId}
                      handleCloseChatting={closeChatWithAnimation}
                    />
                  </Col>
                )}

                {/* User List Column */}
                <Col
                  className="app-joblist-sender-list-section bg-white"
                  xl={selectedApplicantId ? 5 : 12}
                >
                  <Row className="border-bottom position-relative">
                    <Col
                      className=" align-items-center p-2 app-joblist-messaging-before cursorpointer ms-0 d-flex justify-content-center"
                      onClick={handleIconClick}
                    >
                      Messaging
                      <FontAwesomeIcon
                        className="position-absolute end-0 me-2 "
                        icon={showUsername ? faChevronDown : faChevronUp}
                      />
                    </Col>
                  </Row>

                  {error ? (
                    <p className="text-danger">Error: {error}</p>
                  ) : !isUsersLoaded ? (
                    <p>Loading users...</p>
                  ) : (
                    showUsername && (
                      <Col className="" style={{ maxHeight: "" }}>
                        {showUsers.map((user, index) => {
                          return (
                            <Row
                              key={index}
                              onClick={() => handleApplicantClicks(user.id)}
                              className="cursorpointer border-bottom d-flex align-items-center"
                            >
                              <div
                                className="rounded-circle bg-secondary ms-2 my-1 me-2 d-flex justify-content-center align-items-center"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  color: "white",
                                  fontWeight: "",
                                }}
                              >
                                {user.name.charAt(0).toUpperCase()}{" "}
                                {/* Display the first letter of the user's name */}
                              </div>
                              <Col className="p-0 ">
                                <Col className="app-joblist-username-color">
                                  {user.name}
                                </Col>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </>
    );
  }
};

export default JobList;
