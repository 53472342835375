import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Bootstrap components
import {
  Form,
  Card,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";

// Fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faXmark,
  faCheck,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

// User defined components
import Appbar from "../../components/Appbar";
import axiosInstance from "../../config/Axios";
import { useIsMobile } from "../../config/Screen";

const InfluencerJobCreate = () => {
  const navigate = useNavigate();

  // State variables
  const [title, setTitle] = useState("");
  const [purpose, setPurpose] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [sdate, setsdate] = useState("");
  const [edate, setedate] = useState("");
  const [stime, setstime] = useState("");
  const [etime, setetime] = useState("");
  const [isPaid, setIsPaid] = useState(false);

  const [looks, setLooks] = useState({});
  const [gender, setGender] = useState({});
  const [jobType, setJobType] = useState({});
  const [budget, setBudget] = useState({});
  const [deliverables, setDeliverables] = useState({});
  const [deadlines, setDeadlines] = useState({});

  // Error state variables
  const [titleError, setTitleError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [edateError, setedateError] = useState("");
  const [sdateError, setsdateError] = useState("");
  const [etimeError, setetimeError] = useState("");
  const [stimeError, setstimeError] = useState("");
  const [budgetError, setBudgetError] = useState("");
  const [deliverablesError, setDeliverablesError] = useState("");
  const [deadlinesError, setDeadlinesError] = useState("");
  const [looksError, setlooksError] = useState("");

  // Other variables
  const [step, setStep] = useState(1);
  const [contentVisible, setContentVisible] = useState(false);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const categories = useSelector(({ jobs }) => jobs.categories);
  const locations = useSelector(({ jobs }) => jobs.locations);

  const flag = 1;
  const today = new Date().toISOString().split("T")[0];
  const isMobile = useIsMobile();
  const budgetInputRef = useRef();
  const deliverablesInputRef = useRef();
  const deadlinesInputRef = useRef();

  useEffect(() => {
    const defaultGender = selectedCategories.reduce(
      (acc, curr) => ({ ...acc, [curr]: "any" }),
      {}
    );
    setGender(defaultGender);
    const defaultJobType = selectedCategories.reduce(
      (acc, curr) => ({ ...acc, [curr]: "project" }),
      {}
    );
    setJobType(defaultJobType);
  }, [selectedCategories]);

  useEffect(() => {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes;

    console.log("Current Time:", currentTime, strTime);
    if (deadlines === sdate && deadlines < today) {
      setDeadlines(sdate);
    }
  }, [stime, etime, sdate, edate, deadlines, today]);

  useEffect(() => {
    if (budget < 0) {
      setBudget(0);
    }
  }, [budget]);

  useEffect(() => {
    budgetInputRef.current && budgetInputRef.current.focus();
  }, [budget]);

  useEffect(() => {
    deliverablesInputRef.current && deliverablesInputRef.current.focus();
  }, [deliverables]);

  useEffect(() => {
    deadlinesInputRef.current && deadlinesInputRef.current.focus();
  }, [deadlines]);

  function nextCategory() {
    const currentCategory = selectedCategories[currentCategoryIndex];

    if (isPaid && (!budget[currentCategory] || !budget[currentCategory])) {
      setBudgetError("Budget is required.");
      return;
    }
    setBudgetError("");

    if (!deliverables[currentCategory] || !deliverables[currentCategory]) {
      setDeliverablesError("Deliverables are required.");
      return;
    }
    setDeliverablesError("");
    if (
      (currentCategory === "Editor" ||
        currentCategory === "Choreographer" ||
        currentCategory === "Content Writer") &&
      (!deadlines[currentCategory])
    ) {
      setDeadlinesError("Deadlines are required.");
      return;
    }
    setDeadlinesError("");
    if (currentCategoryIndex < selectedCategories.length - 1) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    }
    else {
      setStep(step + 1);
    }
  }

  function previousCategory() {
    if (step === 3) {
      setStep(step - 1);
    } else if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
    } else {
      setStep(step - 1);
    }
  }

  const handleCategoryChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setSelectedCategories(
      selectedCategories.filter((category) => category !== categoryToRemove)
    );
  };

  const handleIconClick = () => {
    setContentVisible(!contentVisible);
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (selectedCategories.length === 0) {
      alert("Please select atleast one category");
      return;
    }
    if (!title) {
      setTitleError("Title is required.");
      return;
    }
    setTitleError("");
    if (title.length < 5) {
      setTitleError("Title must have 5 characters.");
      return;
    }
    setTitleError("");

    if (!location) {
      setLocationError("Location is required.");
      return;
    }
    setLocationError("");

    if (!purpose) {
      setPurposeError("Purpose is required.");
      return;
    }
    setPurposeError("");
    if (purpose.length < 10) {
      setPurposeError("Purpose must have 10 characters.");
      return;
    }
    setPurposeError("");

    if (new Date(sdate) < new Date(today)) {
      setsdateError("Start Date cannot be in the past.");
      return;
    }
    setsdateError("");

    if (!edate) {
      setedateError("End Date is required.");
      return;
    }
    setedateError("");

    if (new Date(edate) < new Date(sdate)) {
      setedateError("End Date cannot be before Start Date.");
      return;
    }
    setedateError("");

    if (!sdate) {
      setsdateError("Start Date is required.");
      return;
    }
    setsdateError("");

    if (!edate) {
      setedateError("End Date is required.");
      return;
    }
    setedateError("");
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes;

    if (sdate === today && stime < strTime) {
      setstimeError("Start time cannot be in the past.");
      return;
    }

    if (edate === sdate && etime < stime) {
      // setetime(stime);
      setetimeError("End time cannot be before start time on the same day.");
      return;
    }
    if (!stime) {
      setstimeError("Start Time is required.");
      return;
    }
    setstimeError("");

    if (!etime) {
      setetimeError("End Time is required.");
      return;
    }
    setetimeError("");
    setStep(step + 1);
  };

  const handleCreatePost = async () => {
    const categoryBudgetMap = [];
    for (let i = 0; i < selectedCategories.length; i++) {
      const categoryName = selectedCategories[i];
      const categoryJobType = jobType[categoryName];
      const categoryGender = gender[categoryName];
      const categoryAmount = budget[categoryName];
      const categoryDeliverables = deliverables[categoryName];
      const categoryDeadlines = deadlines[categoryName];
      const categoryLooks = looks[categoryName];
      const category = categories.find((cat) => cat.name === categoryName);

      console.log("Category:", category);
      if (category) {
        const categoryBudget = {
          category: category.id,
          jobType: categoryJobType,
          // subCategory,
          // duration,
          looks: categoryLooks,
          gender: categoryGender,
          budget: categoryAmount,
          deliveries: categoryDeliverables,
          timelines: categoryDeadlines,
          //role,
        };
        categoryBudgetMap.push(categoryBudget);
      }
    }

    const startDate = new Date(`${sdate}T${stime}`);
    const endDate = new Date(`${edate}T${etime}`);

    const post = {
      title,
      content: purpose,
      location,
      categoryBudget: categoryBudgetMap,
      jobStartDate: startDate,
      jobEndDate: endDate,
      isPaid,
      isBoosted: false,
      boostBudget: 0,
    };

    console.log("Post:", post);
    const response = await axiosInstance.post("/posts", post);
    console.log("Response:", response);
    if (response.status === 201) {
      alert("Post created successfully.");
      navigate("/feed");
    } else {
      alert("Failed to create post.");
    }
  };

  function calculateMaxDate(startDate) {
    if (!startDate) return "";
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + 12);
    return date.toISOString().split("T")[0];
  }

  const incrementValue = (category) => {
    setLooks((prevLooks) => ({
      ...prevLooks,
      [category]: (prevLooks[category] || 0) + 1,
    }));
  };

  const decrementValue = (category) => {
    setLooks((prevLooks) => ({
      ...prevLooks,
      [category]: Math.max((prevLooks[category] || 0) - 1, 0),
    }));
  };

  function showCategories() {
    return (
      <Card className={"mt-2 app-job-create-mobile-CategoryCard"}>
        <Row className="app-scrollable-categories">
          <Row className="mb-3">
            {categories.map((category, index) => (
              <Form.Check
                key={index}
                className="app-filter-checkbox ms-4 mt-2"
                type="checkbox"
                label={category.name}
                id={category.name}
                checked={selectedCategories.includes(category.name)}
                onChange={() => handleCategoryChange(category.name)}
              />
            ))}
          </Row>
        </Row>
      </Card>
    );
  }

  function createJobHeader() {
    return (
      <Row className="mt-2">
        <h1 className="app-job-create-heading">Create A Job</h1>
        <p className="app-job-create-sub-heading">
          Tell us more about your company, job and influencer
        </p>
      </Row>
    );
  }

  function PageFirstHeader() {
    return (
      <>
        <Row className="app-job-create-AboutCompany ms-1 me-1  mt-3 align-items-center">
          <Col
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className="ms-2 app-job-create-AboutCompany-flag"
          >
            {flag}
          </Col>
          <Col className="ms-2 me-3 position-relative">
            About collaboration
            <span className="position-absolute end-0">{flag}/2</span>
          </Col>
        </Row>
      </>
    );
  }
  function PageSecondHeader() {
    return (
      <>
        <Row className="app-job-create-AboutCompany ms-1 me-1  mt-3 align-items-center">
          <Col
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className="ms-2 app-job-create-AboutCompany-flag"
          >
            {flag + 1}
          </Col>
          <Col className="ms-2 me-3 position-relative">
            Job overview
            <span className="position-absolute end-0">{flag + 1}/2</span>
          </Col>
        </Row>
      </>
    );
  }

  function PageFirstDesktopHeader() {
    return (
      <>
        <Row className="positoin-relative app-job-create-AboutCompany align-items-center mt-2 ms-0">
          <Col className="position-absolute d-flex justify-content-center align-items-center" lg={5} md={5} sm={5} xs={5}>
            <span className="app-job-create-AboutCompany-flag me-1" > {flag} </span>About collaboration
          </Col>
          <Col className="position-absolute text-muted end-0 me-2 d-flex justify-content-center align-items-center" lg={5} md={5} sm={5} xs={5}>
            <span className="app-job-create-AboutCompany-flag2 me-1" > {flag + 1} </span>Job overview
          </Col>
        </Row>
      </>
    );
  }

  function PageSecondDesktopHeader() {
    return (
      <>
        <Row className="positoin-relative app-job-create-AboutCompany align-items-center mt-2 ms-0">
          <Col className="position-absolute d-flex justify-content-center align-items-center" lg={5} md={5} sm={5} xs={5}>
            <span className="app-job-create-AboutCompany-flag me-1" >  <FontAwesomeIcon icon={faCheck} /></span>About collaboration
          </Col>
          <Col className="position-absolute end-0 me-2 d-flex justify-content-center align-items-center" lg={5} md={5} sm={5} xs={5}>
            <span className="app-job-create-AboutCompany-flag me-1" > {flag + 1} </span>Job overview
          </Col>
        </Row>
      </>
    );
  }

  function PageFirst() {
    return (
      <Card className="app-job-create-influencer-card">
        {createJobHeader()}
        {isMobile ? <PageFirstHeader /> : <PageFirstDesktopHeader />}
        <Row className="">
          <Form onSubmit={handleContinue}>
            <Form.Group controlId="">
              <Row onClick={handleIconClick}>
                <InputGroup className="">
                  <Form.Control
                    className="app-job-create-form-input mt-3 "
                    type="tel"
                    placeholder={
                      selectedCategories.length > 0
                        ? `${selectedCategories.length} category`
                        : "Select category*"
                    }
                    readOnly
                  />
                  <InputGroup.Text className="app-login-job-create-selected mt-3">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      onClick={handleIconClick}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Row>
              {contentVisible && showCategories()}
              {!contentVisible && (
                <Col className="d-flex app-jobList-FilterCards-overflow ms-1">
                  {selectedCategories.map((category, index) => (
                    <Button
                      className="app-job-list-badge ms-2 mt-3"
                      key={index}
                      onClick={() => handleRemoveCategory(category)}
                    >
                      {category}
                      <span>
                        {" "}
                        <FontAwesomeIcon icon={faXmark} />
                      </span>
                    </Button>
                  ))}
                </Col>
              )}
              <Form.Control
                className="app-job-create-form-input mt-3"
                type="text"
                placeholder="Title*"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                isInvalid={!!titleError}
                required
              />
              <Form.Control.Feedback type="invalid">
                {titleError}
              </Form.Control.Feedback>
              <Form.Select
                className="app-job-create-form-input mt-3"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                isInvalid={!!locationError}
                required
              >
                <option value="">Select location*</option>
                {locations.map((location, index) => (
                  <option key={index} value={location.name}>
                    {location.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {locationError}
              </Form.Control.Feedback>
              <Form.Control
                className="app-job-create-form-input mt-3"
                as="textarea"
                rows={4}
                placeholder="Purpose*"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                isInvalid={!!purposeError}
                required
              />
              <Form.Control.Feedback type="invalid">
                {purposeError}
              </Form.Control.Feedback>
              <Row className="mt-3">
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="">
                  <p className="app-job-create-form-label g-0 mb-0">
                    Start Date*
                  </p>
                  <Form.Control
                    className="app-job-create-form-input-datetime"
                    type="date"
                    placeholder="Starts date*"
                    value={sdate}
                    onChange={(e) => setsdate(e.target.value)}
                    isInvalid={!!sdateError}
                    min={today}
                    max={calculateMaxDate(today)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {sdateError}
                  </Form.Control.Feedback>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <p className="app-job-create-form-label g-0 mb-0">
                    End Date*
                  </p>
                  <Form.Control
                    className="app-job-create-form-input-datetime"
                    type="date"
                    placeholder="End date*"
                    value={edate}
                    onChange={(e) => setedate(e.target.value)}
                    isInvalid={!!edateError}
                    min={sdate === "" ? today : sdate}
                    max={calculateMaxDate(sdate)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {edateError}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="">
                  <p className="app-job-create-form-label g-0 mb-0">
                    Start time*
                  </p>
                  <Form.Control
                    className="app-job-create-form-input-datetime"
                    type="time"
                    placeholder="Start time*"
                    value={stime}
                    onChange={(e) => setstime(e.target.value)}
                    isInvalid={!!stimeError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {stimeError}
                  </Form.Control.Feedback>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} >
                  <p className="app-job-create-form-label g-0 mb-0">
                    End time*
                  </p>
                  <Form.Control
                    className="app-job-create-form-input-datetime"
                    type="time"
                    placeholder="End time*"
                    value={etime}
                    onChange={(e) => setetime(e.target.value)}
                    isInvalid={!!etimeError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {etimeError}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <div className="app-influencerjobcreate-container">
              <Row className="ms-0 mt-2 app-job-create-platform-h2 poppins-medium">
                Type of Collaboration
              </Row>
              <Col className="d-flex">
                <Form.Check
                  className="mt-3 ms-2 app-influencerjobcreate-btns"
                  type="radio"
                  label="Collab"
                  value="collab"
                  id="collab"
                  checked={isPaid === false}
                  onChange={() => setIsPaid(false)}
                />
                <Form.Check
                  className="mt-3 ms-3 app-influencerjobcreate-btns"
                  type="radio"
                  label="Paid"
                  value="paid"
                  id="paid"
                  checked={isPaid === true}
                  onChange={() => setIsPaid(true)}
                />
              </Col>
            </div>
            <Button
              className="mb-3 mt-3 app-job-create-button poppins-semibold"
              variant="primary"
              type="submit"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Form>
        </Row>
      </Card >
    );
  }


  function PageSecond({ category }) {
    const currentGender = gender[category];
    const currentJobType = jobType[category];
    return (
      <Card className="app-job-create-influencer-card">
        <Link
          className="mb-3 ms-2"
          variant="primary"
          type="submit"
          onClick={previousCategory}
        >
          Back
        </Link>
        <Row className="mt-0">
          <h1 className="app-job-create-heading">Create A Job</h1>
          <p className="app-job-create-sub-heading">
            Describe the roles and responsibilities for this requirement.
          </p>
        </Row>
        {isMobile ? <PageSecondHeader /> : <PageSecondDesktopHeader />}
        <Row>
          <Col className="mt-3 poppins-semibold app-job-create-h3 ">
            {category} details
          </Col>
        </Row>
        <div className="app-influencerjobcreate-container">
          <Row className="ms-0 mt-3 app-job-create-platform-h2 poppins-medium">
            Gender preference
          </Row>
          <Col className="d-flex">
            <Form.Check
              className="mt-3 ms-0 me-2 app-influencerjobcreate-btns"
              type="radio"
              label="Any"
              id="Any"
              checked={currentGender === "any"}
              onChange={() =>
                setGender((prevGender) => ({
                  ...prevGender,
                  [category]: "any",
                }))
              }
            />
            <Form.Check
              className="mt-3 ms-0 me-2 app-influencerjobcreate-btns"
              type="radio"
              label="Male"
              id="Male"
              checked={currentGender === "male"}
              onChange={() =>
                setGender((prevGender) => ({
                  ...prevGender,
                  [category]: "male",
                }))
              }
            />
            <Form.Check
              className="mt-3 ms-0 app-influencerjobcreate-btns"
              type="radio"
              label="Female"
              id="Female"
              checked={currentGender === "female"}
              onChange={() =>
                setGender((prevGender) => ({
                  ...prevGender,
                  [category]: "female",
                }))
              }
            />
          </Col>
        </div>
        <div className="app-influencerjobcreate-container">
          <Row className="ms-0 mt-3 app-job-create-platform-h2 poppins-medium">
            Type of job
          </Row>
          <Col className="d-flex">
            <Form.Check
              className="mt-3 ms-0 me-2 app-influencerjobcreate-btns"
              type="radio"
              label="Project Based"
              id="Project Based"
              checked={currentJobType === "project"}
              onChange={() =>
                setJobType((prevJobType) => ({
                  ...prevJobType,
                  [category]: "project",
                }))
              }
            />
            <Form.Check
              className="mt-3 ms-0 app-influencerjobcreate-btns"
              type="radio"
              label="Full Time"
              id="Full Time"
              checked={currentJobType === "full"}
              onChange={() =>
                setJobType((prevJobType) => ({
                  ...prevJobType,
                  [category]: "full",
                }))
              }
            />
          </Col>
        </div>
        <Row>
          {(category === "Hair Stylist" ||
            category === "Makeup Artist" ||
            category === "Designer" ||
            category === "Model") && (
              <Col className="ms-0 mt-4 mb-4 me-0 position-relative app-influencerjobcreate-container">
                No. of looks
                <span className="position-absolute end-0">
                  <Button
                    className="app-job-create-addPlatformBtn me-4"
                    variant="primary"
                    type="submit"
                  >
                    <Row>
                      <Col>
                        <FontAwesomeIcon
                          icon={faMinus}
                          onClick={() => decrementValue(category)}
                        />
                      </Col>
                      <Col>{looks[category] || 0}</Col>
                      <Col>
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => incrementValue(category)}
                        />
                      </Col>
                    </Row>
                  </Button>
                </span>
              </Col>
            )}
        </Row>
        <Row className="ms-0 me-2">
          {isPaid && (
            <>
              <Form.Control
                ref={budgetInputRef}
                className="app-job-create-form-input mt-3"
                type="number"
                placeholder="Budget"
                value={budget[category] || ""}
                onChange={(e) =>
                  setBudget((prevBudget) => ({
                    ...prevBudget,
                    [category]: e.target.value,
                  }))
                }
                isInvalid={!!budgetError}
                required
              />
              <Form.Control.Feedback type="invalid">
                {budgetError}
              </Form.Control.Feedback>
            </>
          )}
          <Form.Control
            ref={deliverablesInputRef}
            className="app-job-create-form-input mt-3"
            type="textarea"
            row={4}
            placeholder="Deliverables"
            value={deliverables[category]}
            onChange={(e) =>
              setDeliverables((prevDeliverables) => ({
                ...prevDeliverables,
                [category]: e.target.value,
              }))
            }
            isInvalid={!!deliverablesError}
            required
          />
          <Form.Control.Feedback type="invalid">
            {deliverablesError}
          </Form.Control.Feedback>
          {(category === "Editor" ||
            category === "Choreographer" ||
            category === "Content Writer") && (
              <>
                <Row className="mt-3 app-login-influencer-timer ms-0">Deadline date</Row>
                <Form.Control
                  ref={deadlinesInputRef}
                  className="app-job-create-form-input"
                  type='date'
                  // placeholder="Deadlines"
                  value={deadlines[category]}
                  onChange={(e) =>
                    setDeadlines((prevDeadlines) => ({
                      ...prevDeadlines,
                      [category]: e.target.value,
                    }))
                  }
                  min={today}
                  max={calculateMaxDate(today)}
                  isInvalid={!!deadlinesError}
                  required
                  title="Select deadline date"
                />
                <Form.Control.Feedback type="invalid">
                  {deadlinesError}
                </Form.Control.Feedback>
              </>
            )}
          {/* <InputGroup className="">
            <Form.Control
              className="app-job-create-form-input mt-3"
              type="tel"
              placeholder="Reference links*"
              readOnly
            />
          </InputGroup> */}
          <Button
            className="mb-3 mt-4 app-job-create-button poppins-semibold"
            variant="primary"
            type="submit"
            onClick={nextCategory}
          >
            Next
          </Button>

        </Row>
      </Card>
    );
  }

  function PageThree() {
    return (
      <Card className="app-job-create-influencer-card">
        <Link
          className="mb-3 mt-3 ms-2"
          variant="primary"
          type="submit"
          onClick={previousCategory}
        >
          Back
        </Link>
        {createJobHeader()}
        {isMobile ? <PageSecondHeader /> : <PageSecondDesktopHeader />}
        <Row>
          <Col className="mt-3 poppins-semibold app-job-create-h3 ms-0">
            Review your post
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Title:</h5>
            <p>{title}</p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Purpose:</h5>
            <p>{purpose}</p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Categories:</h5>
            <Row className="ms-0">
              {selectedCategories.map((category, index) => (
                <li key={index}>
                  {category}
                  {":"}
                  {isPaid && (
                    <span className="ms-2">
                      {"INR "}
                      {budget[category]}
                      {" /-"}
                    </span>
                  )}
                </li>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Location:</h5>
            <p>{location}</p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Start Date:</h5>
            <p>
              {sdate} @ {stime}
            </p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">End Date:</h5>
            <p>
              {edate} @ {etime}
            </p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3 app-job-create-form-label">
          <Col>
            <h5 className="poppins-medium">Paid:</h5>
            <p>{isPaid ? "Yes" : "No"}</p>
          </Col>
        </Row>
        <Row className="ms-0 mt-3">
          <Col>
            <Button
              className="mb-3 mt-3 ms-0 app-job-create-button poppins-semibold"
              variant="primary"
              type="submit"
              onClick={handleCreatePost}
            >
              Create Post
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  if (isMobile) {
    return (
      <>
        <Appbar />
        {step === 1 && PageFirst()}
        {step === 2 && (
          <PageSecond category={selectedCategories[currentCategoryIndex]} />
        )}
        {step === 3 && PageThree()}
      </>
    );
  } else {
    return (
      <>
        <Appbar />
        <Container className="justify-content-center align-items-center">
          {step === 1 && PageFirst()}
          {step === 2 && (
            <PageSecond category={selectedCategories[currentCategoryIndex]} />
          )}
          {step === 3 && PageThree()}
        </Container>
      </>
    );
  }
};

export default InfluencerJobCreate;